import { ConfigProvider } from "antd";
import locale from "antd/locale/es_ES";
import { Provider } from "react-redux";
import store from "../app/store";
import NodeApp from "./NodeApp.jsx";

const App = () => {
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          fontFamily: "Poppins",
          colorPrimary: "#652D52",
          borderRadius: 8,
        },
      }}
    >
      <Provider store={store}>
        <NodeApp />
      </Provider>
    </ConfigProvider>
  );
};

export default App;
