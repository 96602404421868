import isEqual from "lodash";
import moment from "moment";
export const countDecimal = (numero) => {
  const numStr = String(numero);

  const puntoDecimal = numStr.indexOf(".");

  if (puntoDecimal === -1) return 0;

  return numStr.length - puntoDecimal - 1;
};

export function camelCase(str) {
  if (typeof str !== "string") {
    return "";
  }
  if (str.includes(" ")) {
    const words = str.split(" ");

    const camelCasedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return camelCasedWords.join(" ");
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}
export const capitalizeWord = (month) => {
  return month ? month.charAt(0).toUpperCase() + month.slice(1) : "Mes";
};

export const arePropsEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export function compararFechas(a, b) {
  const date1 = a?.date?.split("-");
  const date2 = b?.date?.split("-");
  if (!date1 || !date2) {
    return 0;
  }
  const dateHours1 = date1[5].split(":");
  const dateHours2 = date2[5].split(":");

  if (!dateHours1 || !dateHours2) {
    return 0;
  }

  const fechaA = new Date(
    date1[4],
    date1[3],
    date1[1],
    dateHours1[0],
    dateHours1[1],
    dateHours1[2]
  );
  const fechaB = new Date(
    date2[4],
    date2[3],
    date2[1],
    dateHours2[0],
    dateHours2[1],
    dateHours2[2]
  );

  if (fechaA.getTime() < fechaB.getTime()) {
    return 1;
  } else if (fechaA.getTime() > fechaB.getTime()) {
    return -1;
  } else {
    return 0;
  }
}

export function getDay(dateDay) {
  let date = new Date(dateDay);
  // Obtiene la fecha actual
  const today = new Date();

  // Compara si el año, mes y día de la fecha dada coinciden con los de hoy
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    return "Hoy";
  }

  // Obtiene la fecha de ayer restando un día a la fecha actual
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // Compara si el año, mes y día de la fecha dada coinciden con los de ayer
  if (
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate()
  ) {
    return "Ayer";
  }

  // La fecha no es ni hoy ni ayer
  return -1;
}

export function getDayStringFull(day) {
  switch (day) {
    case "lu":
      return "Lun";
    case "ma":
      return "Mar";
    case "mi":
      return "Mie";
    case "ju":
      return "Jue";
    case "vi":
      return "Vie";
    case "sá":
      return "Sáb";
    case "do":
      return "Dom";
    default:
      return "day";
  }
}

export const newDateWithTime = () => {
  const dateForm = moment();
  const dateFormated = dateForm.utcOffset(-5);
  const dateFormatedd = dateFormated.format("YYYY-MM-DD HH:mm:ss");
  return dateFormatedd;
};

export const getStringMonthInit = (monthNumber) => {
  switch (monthNumber) {
    case 0:
      return "Ene";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Abr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Ago";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dic";
    default:
      return "";
  }
};

export function shellSort(arr, compareFunction) {
  let n = arr.length;

  for (let gap = Math.floor(n / 2); gap > 0; gap = Math.floor(gap / 2)) {
    for (let i = gap; i < n; i += 1) {
      let temp = arr[i];

      let j;
      for (
        j = i;
        j >= gap && compareFunction(arr[j - gap], temp) > 0;
        j -= gap
      ) {
        arr[j] = arr[j - gap];
      }

      arr[j] = temp;
    }
  }
  return arr;
}

// @params date1, date2: string
// @format date: YYYY-MM-DD HH:mm:ss
//  2023-12-15 10:30:58
export function compareDates(date1, date2) {
  const parsedDate1 = new Date(date1);
  const parsedDate2 = new Date(date2);

  if (isNaN(parsedDate1.getTime()) || isNaN(parsedDate2.getTime())) {
    throw new Error("Fecha inválida proporcionada");
  }

  if (parsedDate1.getTime() > parsedDate2.getTime()) {
    return -1;
  } else if (parsedDate1.getTime() < parsedDate2.getTime()) {
    return 1;
  } else {
    return 0;
  }
}

export const hasCarpentyGroupValidation = (activities) => {
  return activities.some((activity) => activity.group === "carpinteria");
};

export const moneyFormat = (value = "") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
  }).format(value);
};

export const dateTimeFormater = ({ date = "", dateStyle = "short" }) => {
  if (!date) {
    return "Fecha no válida";
  }

  let auxDate = new Date(date);

  if (!(auxDate instanceof Date) || isNaN(auxDate.getTime())) {
    return "Fecha no válida";
  }

  return Intl.DateTimeFormat("es-CO", {
    dateStyle,
  }).format(auxDate);
};
