import { useState } from "react";
import { withRouter } from "react-router-dom";
import BELL_ICON_SVG from "../../../assets/icons/svg/bell.svg";
import HOME_ICON_SVG from "../../../assets/icons/svg/home.svg";
import HOUSE_ICON_SVG from "../../../assets/icons/svg/inmuebles.svg";
import USER_ICON_SVG from "../../../assets/icons/svg/user.svg";
import {
  BASE_MENU_PATH,
  HOME,
  NOTIFICATIONS,
  PROFILE,
  SERVICES,
} from "../../../router/routes";
import { NavbarButton, NextRoundedButton } from "../../atoms/button/Button";
import "./buttonNavbar.css";

const ButtonNavbar = ({
  history,
  page,
  showBtn,
  popupContent,
  callback,
  showCotizationMenu,
}) => {
  const [selected, setSelect] = useState(page);

  const handleSelected = (e) => {
    setSelect(e.target.id);
    history.push(`${BASE_MENU_PATH}${e.target.id}`);
  };

  return (
    <>
      <div className="moreContainer">
        {page === HOME && (
          <NextRoundedButton show={showBtn} callback={callback} />
        )}
      </div>
      <div className="button-navbar-container">
        <div className="buttonContainer">
          <NavbarButton
            icon={HOUSE_ICON_SVG}
            txt="Servicios"
            id={SERVICES}
            selected={selected}
            callback={handleSelected}
          />
        </div>

        <div className="buttonContainer">
          <NavbarButton
            icon={BELL_ICON_SVG}
            txt="Notificaciones"
            id={NOTIFICATIONS}
            selected={selected}
            callback={handleSelected}
          />
        </div>

        <div className="buttonContainer">
          <NavbarButton
            icon={USER_ICON_SVG}
            txt="Perfil"
            id={PROFILE}
            selected={selected}
            callback={handleSelected}
          />
        </div>

        {showCotizationMenu && (
          <div className="buttonContainer">
            <NavbarButton
              icon={HOME_ICON_SVG}
              txt="Home"
              id={HOME}
              selected={selected}
              callback={handleSelected}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(ButtonNavbar);
