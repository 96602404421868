import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { reducerProperties } from "../../reducers/app.reducer";
import { propertyReducer } from "../../reducers/area.property.reducer";
import { authReducer } from "../../reducers/auth.reducer";
import { reducerFormEntry } from "../../reducers/form.entry.reducer";
import { reducerFormManagement } from "../../reducers/form.management.reducer";
import { reducerFormProperty } from "../../reducers/form.property.reducer";
import { reducerServieProviderForm } from "../../reducers/form.service.provider";
import { reducerFormUser } from "../../reducers/form.user.reducer";
import globalStateReducer from "../../reducers/globalState.reducer";
import { reducerMessage } from "../../reducers/messages.reducer";
import { reducerRequestQuoteItem } from "../../reducers/quote.item.reducer";
import { reducerRequestService } from "../../reducers/request.service.reducer";
import { reducerServicesList } from "../../reducers/servicesList.reducer";
import { reducerStateEntry } from "../../reducers/state.entry.reducer";
import { chatControllerReducer } from "reducers/chat.controller.reducer";
import { reducerStateActivity } from "../../reducers/activityState.reducer";

export default configureStore(
  {
    reducer: {
      app: reducerProperties,
      request_service: reducerRequestService,
      services_list: reducerServicesList,
      form_user: reducerFormUser,
      form_property: reducerFormProperty,
      form_management: reducerFormManagement,
      form_service_provider: reducerServieProviderForm,
      form_quotes_item: reducerRequestQuoteItem,
      form_entry: reducerFormEntry,
      messages_list: reducerMessage,
      state_entry: reducerStateEntry,
      areas_property: propertyReducer,
      globalState: globalStateReducer,
      auth: authReducer,
      chatController: chatControllerReducer,
      activityState: reducerStateActivity,
    },
  },
  applyMiddleware(thunk)
);
