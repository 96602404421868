import { Button } from "antd";
import { ReactComponent as ArrowBackIcon } from "assets/icons/svg/arrow-back.svg";
import { useHistory } from "react-router-dom";
import "./GoBackBtn.css";

export default function GoBackBtn({ absolute, type }) {
  const history = useHistory();

  return (
    <Button
      style={
        absolute
          ? { position: "absolute", top: "1rem", left: "1rem", zIndex: 4 }
          : {}
      }
      className="go-back-btn"
      onClick={() => {
        history.goBack();
      }}
      type={type ? type : "primary"}
      shape="circle"
    >
      <ArrowBackIcon />
    </Button>
  );
}
