import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { ALL_SERVICES_ADDED } from "router/routes";

export default function AddNewOptions() {
  const history = useHistory();

  return (
    <section className="add-new-option">
      <Button
        size="large"
        onClick={() => { history.push(ALL_SERVICES_ADDED) }}
      >
        Nuevo avance
      </Button>
      <Button size="large">Nuevo imprevisto</Button>
      <Button size="large">Pedir material adicional</Button>
    </section>
  )
}
