//state entry reducer

const ACTIVITY_STATE = "activity_state";

const initialStateActivity = {
  activity: {
    name: "",
    to_complete: null,
    completed: null,
    key: "",
    cat: "",
  },
};

const reducerStateActivity = (state = initialStateActivity, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIVITY_STATE:
      return {
        ...state,
        activity: payload,
      };

    default:
      return state;
  }
};

const setActivityState = (value) => {
  return { type: ACTIVITY_STATE, payload: value };
};

export { initialStateActivity, reducerStateActivity, setActivityState };
