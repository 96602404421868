import { ReactComponent as BricksIcon } from "assets/icons/svg/bricks.svg?react";
import { ReactComponent as DoorIcon } from "assets/icons/svg/door.svg?react";
import { ReactComponent as PaintRollerIcon } from "assets/icons/svg/paint-roller.svg?react";
import { ReactComponent as PanelsRightBottomIcon } from "assets/icons/svg/panels-right-bottom.svg?react";
import { ReactComponent as PersonDiggingIcon } from "assets/icons/svg/person-digging.svg?react";
import { ReactComponent as StarsIcon } from "assets/icons/svg/stars.svg?react";
import { ReactComponent as TextGrammarLightningIcon } from "assets/icons/svg/text-grammar-lightning.svg?react";
import { ReactComponent as TowelsIcon } from "assets/icons/svg/towels.svg?react";
import { ReactComponent as ViewTileIcon } from "assets/icons/svg/view-tile.svg?react";
import { ReactComponent as WreckingBallIcon } from "assets/icons/svg/wrecking-ball.svg?react";

export const categoriesService = [
  { key: "veneer", text: "Revestimientos", icon: ViewTileIcon },
  { key: "mortar", text: "Mortero", icon: PersonDiggingIcon },
  { key: "black_work", text: "Obra negra", icon: BricksIcon },
  { key: "white_work", text: "Obra blanca", icon: PaintRollerIcon },
  { key: "light_system", text: "Sistema liviano", icon: PanelsRightBottomIcon },
  { key: "installation_and_electricity", text: "Instalación y electricidad", icon: TextGrammarLightningIcon },
  { key: "element_demolition", text: "Demolición de elementos", icon: WreckingBallIcon },
  { key: "finishes", text: "Acabados", icon: StarsIcon },
  { key: "carpentry", text: "Carpintería", icon: DoorIcon },
  { key: "accessories_and_equipment", text: "Accesorios y equipamientos", icon: TowelsIcon }
];