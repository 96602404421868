import { Button, ConfigProvider, Modal } from "antd";
import { ReactComponent as BellLightIcon } from "assets/icons/svg/bell-light.svg";
import { ReactComponent as PlusSignIcon } from "assets/icons/svg/plus-sign.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/svg/profile.svg";
import { ReactComponent as QrCodeScanIcon } from "assets/icons/svg/qr-code-scan.svg";
import { ReactComponent as ServicesIcon } from "assets/icons/svg/services.svg";
import { ReactComponent as TimelineIcon } from "assets/icons/svg/map-icon.svg";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CHECK_QR_CODE,
  NOTIFICATIONS,
  PROFILE,
  PS_BASE_MENU_PATH,
  ROUTE_MAP,
  ROUTE_TIMELINE,
  SERVICES,
} from "router/routes";
import "./NavBar.css";
import AddNewOption from "./components/AddNewOptions";

export default function NavBar() {
  const history = useHistory();
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);

  return (
    <>
      <nav className="nav-bar">
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimaryHover: "#652d52",
                defaultActiveBg: "#fff",
              },
            },
          }}
        >
          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + SERVICES);
            }}
            data-active={
              history.location.pathname.includes(SERVICES || "/home") ||
              history.location.pathname.includes("/home")
            }
            className="nav-bar-opt"
          >
            <ServicesIcon />
          </Button>

          <Button
            size="large"
            onClick={() => {
              history.push(CHECK_QR_CODE);
            }}
            data-active={history.location.pathname.includes(CHECK_QR_CODE)}
            className="nav-bar-opt"
          >
            <QrCodeScanIcon />
          </Button>

          <Button
            size="large"
            onClick={() => {
              setIsAddNewModalOpen(true);
            }}
            className="nav-bar-opt"
          >
            <PlusSignIcon />
          </Button>

          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + NOTIFICATIONS);
            }}
            data-active={history.location.pathname.includes(NOTIFICATIONS)}
            className="nav-bar-opt"
          >
            <BellLightIcon />
          </Button>

          <Button
            size="large"
            onClick={() => {
              history.push(ROUTE_TIMELINE);
            }}
            data-active={history.location.pathname.includes(ROUTE_TIMELINE)}
            className="nav-bar-opt"
          >
            <TimelineIcon />
          </Button>

          <Button
            size="large"
            onClick={() => {
              history.push(PS_BASE_MENU_PATH + PROFILE);
            }}
            data-active={history.location.pathname.includes(PROFILE)}
            className="nav-bar-opt"
          >
            <ProfileIcon />
          </Button>
        </ConfigProvider>
      </nav>

      <Modal
        centered
        closable
        maskClosable
        footer={null}
        title={null}
        open={isAddNewModalOpen}
        onCancel={() => {
          setIsAddNewModalOpen(false);
        }}
      >
        <AddNewOption />
      </Modal>
    </>
  );
}
