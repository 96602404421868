import { Scanner } from "@yudiel/react-qr-scanner";
import { Avatar, message } from "antd";
import jpSubPicture from "assets/icons/png/jp-sub-picture.png";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import NavBar from "components/Navbar/NavBar";
import { useSelector } from "react-redux";
import { convertRoleCodeToName } from "res/roles";
import "./CheckQrCode.css";
import { passOrderToOnSite } from "services-controller/http.cleinte.controller";
import QrModalReponse from "./QrModalReponse";
import { useState } from "react";
import { auth } from "firebase";

export default function CheckQrCode() {
  const { user } = useSelector((state) => state.auth);
  const [openMessage, setOpenMessage] = useState(false);
  const [response, setResponse] = useState({});

  const extractQrData = (item) => {
    const rawValue = JSON.parse(item.rawValue);
    return {
      orderId: rawValue.orderId,
      serviceId: rawValue.serviceId,
    };
  };

  const handleScan = async (item) => {
    const order = extractQrData(item);
    let response = null;

    console.log(user);
    try {
      response = await passOrderToOnSite(order.serviceId, order.orderId, user);
      setOpenMessage(true);
      setResponse(response);
    } catch (error) {
      console.log(error);
      // message.error(error?.response?.data?.message);
      setResponse({
        error: true,
        message: error?.response?.data?.message,
      });

      setOpenMessage(true);
      // let responeError = JSON.parse(error.response.data);
      // console.log(responseError, "error");
    }
  };
  return (
    <main className="check-qr-code">
      <GoBackBtn />

      <header>
        <Avatar size={64} src={user?.register_data.thumbnail || jpSubPicture} />

        <div>
          <span className="user-name">
            {user?.register_data.name || "Sin nombre"}
          </span>
          <span className="user-role">
            {convertRoleCodeToName(user?.query?.userType ?? user.user_type)}
          </span>
        </div>
      </header>

      <section className="qr-container">
        <p>Coloca el código Qr delante de la cámara</p>

        <Scanner
          onScan={async (result) => {
            // const value = result.at(0).rawValue;

            // const response = extractQrData(result.at(0));
            handleScan(result.at(0));
            // const { rawValue } = result.at(0);

            // window.open(rawValue, "_blank");
          }}
          formats={["qr_code"]}
          styles={{
            container: {
              height: "fit-content",
              aspectRatio: "1 / 1",
            },
          }}
          allowMultiple
        />
      </section>

      <NavBar />
      <QrModalReponse
        open={openMessage}
        onCancel={() => setOpenMessage(false)}
        response={response}
      />
    </main>
  );
}
