import SVG_JUSTO from "assets/icons/svg/justo_pago_logo_blanco.svg";
import ALERT_SOUND from "assets/sounds/Ding.wav";
import NavBar from "components/Navbar/NavBar";
import { Appbar } from "components/atoms/appbar/Appbar";
import { NotificationSound } from "components/molecules/notifications/NotificationSound";
import { NotificationArea } from "components/organisms/notifications/NotificationArea";
import { PushNotification } from "components/organisms/push-notifications/PushNotifications";
import useNotifications from "custom-hooks/useNotifications";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { countUnreadNotifications } from "utils/utils.notifications";
import "./psNotifications.css";
const PsNotifications = () => {
  const history = useHistory();
  const [pushTitle, setPushTitle] = useState(null);
  const [pushOptions, setPushOptions] = useState(null);
  const notificationSound = NotificationSound({ src: ALERT_SOUND });
  const [permission, setPermission] = useState(false);

  const {
    notifications,
    unreadNotifications,
    notificationOnClick,
    handleRemoveById,
    getBitacleEntryInfo,
    handleMarkAsRead,
  } = useNotifications();

  useEffect(() => {
    const newUnReadNotificationsCount = countUnreadNotifications(notifications);
    if (unreadNotifications === null) {
      return;
    }

    if (newUnReadNotificationsCount > unreadNotifications) {
      notificationSound.play();
      if (permission) {
        setPushTitle("Nueva Noticia");
        setPushOptions({
          body: "Revisa en la plataforma que hay una nuevo mensaje.",
          icon: SVG_JUSTO,
        });
      }
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <div className="ps-container notifications-container">
      <div className="services-appbar-container">
        <Appbar smallText="Revisa tus" bigText="Notificaciones " />
      </div>

      <div className="ps-notifications">
        <div className="ps-notifications-container today-notifications">
          <NotificationArea
            getBitacleEntryInfo={getBitacleEntryInfo}
            notifications={notifications}
            psNotification={true}
            handleRemoveById={handleRemoveById}
            onHandleNotiClick={(notification) => {
              notificationOnClick(notification, history, true);
            }}
            handleMarkAsRead={handleMarkAsRead}
          />
        </div>
      </div>

      <NavBar />

      <PushNotification
        title={pushTitle}
        options={pushOptions}
        requestPermission={true}
        setPermission={setPermission}
      />
      {notificationSound.Render}
    </div>
  );
};

export default PsNotifications;
