import { Progress, Timeline, Tooltip } from "antd";
import { ReactComponent as LaborIcon } from "assets/icons/svg/labor.svg";
import { ReactComponent as MaterialsIcon } from "assets/icons/svg/materials.svg";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import NavBar from "components/Navbar/NavBar";
import {
  configClass,
  configTitleIcon,
} from "components/molecules/container/Container";
import { useHistory, useParams } from "react-router-dom";
import { categoriesService } from "res/constans";
import { dateTimeFormater, moneyFormat } from "res/utils";
import { useState, useEffect } from "react";
import useReduxState from "custom-hooks/useReduxState";
import { setActivityState } from "reducers/activityState.reducer";
//import { ADVANCE_INVENTORY } from "router/routes";
import {
  getBudgetById,
  getServiceById,
  getServiceOperationById,
} from "services-controller/http.cleinte.controller";
import _ from "lodash";

import "./NewAdvanceDetail.css";

const categoriesProgress = [
  { cat: "veneer", progress: 0 },
  { cat: "mortar", progress: 0 },
  { cat: "black_work", progress: 0 },
  { cat: "white_work", progress: 0 },
  { cat: "light_system", progress: 0 },
  { cat: "installation_and_electricity", progress: 0 },
  { cat: "element_demolition", progress: 0 },
  { cat: "finishes", progress: 0 },
  { cat: "carpentry", progress: 0 },
  { cat: "accessories_and_equipment", progress: 0 },
];

export default function NewAdvanceDetail() {
  const { id } = useParams();
  const history = useHistory();
  const [, dispatchControl] = useReduxState(
    { setActivityState },
    "activityState"
  );
  const [serviceOperation, setServiceOperation] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const [budget, setBudget] = useState({});
  const [activities, setActivities] = useState([]);
  const [categoryProgress, setCategoryProgress] = useState([]);
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    if (serviceOperation?.activities) {
      const activities = serviceOperation.activities.map(
        (activity) => activity
      );
      setActivities(activities);
    }
  }, [serviceOperation]);

  useEffect(() => {
    const phasesMap = activities.reduce((acc, activity) => {
      const phaseId = "Fase 1";
      if (!acc[phaseId]) {
        acc[phaseId] = {
          id: 1,
          phase: phaseId,
          subActivities: [],
          date: new Date(activity.date_init),
        };
      }
      acc[phaseId].subActivities.push({
        key: activity.id,
        name: activity.name,
        missing: activity.missing,
        to_complete: 1,
        completed: activity.completed,
        cat: activity.category,
      });
      return acc;
    }, {});

    const timelineData = Object.values(phasesMap).sort((a, b) => a.id - b.id);
    setTimeline(timelineData);
  }, [activities]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceOperationResponse = await getServiceOperationById(id);
        setServiceOperation(serviceOperationResponse);
      } catch (error) {
        console.error("Error fetching service operation:", error);
      }

      try {
        const serviceResponse = await getServiceById(id);
        setServiceData(serviceResponse);
      } catch (error) {
        console.error("Error fetching service:", error);
      }

      try {
        const budgetResponse = await getBudgetById(id);
        setBudget(budgetResponse);
      } catch (error) {
        console.error("Error fetching budget:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const categories = _.groupBy(activities, (cat) => cat.category);
    const categoryProgress = {};

    Object.keys(categories).forEach((key) => {
      const totalExecutionTime = categories[key].reduce(
        (sum, item) => sum + item.total_execution_time,
        0
      );

      const totalCompletedTime = categories[key].reduce(
        (sum, item) => sum + item.completed * item.total_execution_time,
        0
      );

      const progress = totalCompletedTime / totalExecutionTime;

      categoryProgress[key] = {
        totalExecutionTime,
        progressPercentage: Math.round(progress * 100),
      };
    });

    const updatedProgress = categoriesProgress.map((category) => {
      const categoryInfo = categoryProgress[category.cat];
      return categoryInfo
        ? { ...category, progress: categoryInfo.progressPercentage }
        : category;
    });

    setCategoryProgress(updatedProgress);
  }, [activities]);

  return (
    <main className="new-advance-detail">
      <GoBackBtn />
      <header className="service-details">
        <article className="service-client-info">
          <span
            style={{ fontSize: "16px", marginLeft: "2px" }}
            className="client-info"
          >
            {serviceData?.metadata.user.register_data.name} - {id}
          </span>
          <span
            className={`service-status ${configClass(
              serviceData?.state.stage
            )}`}
          >
            {configTitleIcon(serviceData?.state.stage)}
          </span>
        </article>
        <section className="service-progress-info">
          <article className="money-detail">
            <div>
              <LaborIcon />
              <footer>
                <span>
                  {moneyFormat(budget?.status?.categories?.labor.available)}
                </span>
                <span>
                  {moneyFormat(
                    budget?.status?.categories?.labor.subtract_value
                  )}
                </span>
              </footer>
            </div>
            <div>
              <MaterialsIcon />
              <footer>
                <span>
                  {moneyFormat(budget?.status?.categories?.materials.available)}
                </span>
                <span>
                  {moneyFormat(
                    budget?.status?.categories?.labor.subtract_value
                  )}
                </span>
              </footer>
            </div>
          </article>
          {/* <article className="phases-status">
            {phases.map(({ progress }, index) => {
              return (
                <div className="progress-wrapper" key={index}>
                  <h1 className="progress-format">Fase {index + 1}</h1>
                  <Progress percent={progress} size="small" />
                </div>
              );
            })}
          </article> */}
          <article className="categories-status">
            {categoryProgress?.map(({ cat, progress }, index) => {
              const { icon: Icon, text } = categoriesService.find(
                (item) => item.key === cat
              );
              return (
                <div className="status-container" key={index}>
                  <Progress
                    type="circle"
                    percent={progress}
                    strokeLinecap="butt"
                    size={52}
                    format={(percent) => (
                      <div className="progress-container">
                        <Icon />
                        <span>{percent}%</span>
                      </div>
                    )}
                  />
                  <Tooltip placement="bottom" title={text}>
                    <h5 className="container-title">{text}</h5>
                  </Tooltip>
                </div>
              );
            })}
          </article>
        </section>
      </header>
      <article className="timeline-advance">
        <h1>Cronograma del 26 de julio al 1 agosto</h1>
        <Timeline
          className="timeline-container"
          mode="left"
          items={timeline.map(({ subActivities, phase, date }) => {
            const filteredActivities = subActivities.filter(
              ({ to_complete }) => to_complete > 0
            );

            return {
              children: (
                <section className="timeline-item">
                  <h1>{phase}</h1>
                  {Boolean(filteredActivities.length) && (
                    <time dateTime={date}>
                      {dateTimeFormater({ date, dateStyle: "long" })}
                    </time>
                  )}
                  <Timeline
                    style={{ marginTop: 15 }}
                    items={filteredActivities.map(
                      ({ name, to_complete, completed, cat, key }) => {
                        const { icon: Icon } = categoriesService.find(
                          ({ key }) => key === cat
                        );
                        return {
                          children: (
                            <article
                              className="timeline-sub-item"
                              onClick={() => {
                                dispatchControl.setActivityState({
                                  name,
                                  to_complete,
                                  completed,
                                  key,
                                  cat,
                                });
                                history.push(`/new-advance-form/${id}/${key}`);
                              }}
                            >
                              <div className="sub-item-content">
                                <Icon />
                                <footer>
                                  <p>
                                    {to_complete < 1 &&
                                      to_complete + completed < 1 &&
                                      `avanzar un ${to_complete}% en ${name}`}
                                    {to_complete < 1 &&
                                      completed >= 1 &&
                                      `terminar ${name}`}
                                  </p>
                                  <Progress
                                    percent={completed * 100}
                                    size={[192, 5]}
                                  />
                                </footer>
                              </div>
                              <p>{name}</p>
                            </article>
                          ),
                        };
                      }
                    )}
                  />
                </section>
              ),
            };
          })}
        />
      </article>
      {/* <FloatButton
        className="inventary-button"
        type="primary"
        onClick={() => history.push(ADVANCE_INVENTORY)}
      /> */}
      <NavBar />
    </main>
  );
}
