import { Button, Form, Input, InputNumber, Slider, message } from "antd";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import InputFile from "components/InputFile/InputFile";
//import NavBar from "components/Navbar/NavBar";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { categoriesService } from "res/constans";
import "./NewAdvanceForm.css";
import { uploadMultipleEvidenceResources } from "services-controller/storage";
import { createAdvance } from "services-controller/http.cleinte.controller";
import { useHistory } from "react-router-dom";

export default function NewAdvanceForm() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const { id, key } = useParams();
  const activityState = useSelector((state) => state.activityState);
  const { activity } = activityState;
  const { user } = useSelector((state) => state.auth);
  const { icon: Icon } = categoriesService.find(
    (item) => item.key === "white_work"
  );
  const [progressRange, setProgressRange] = useState(activity.completed);
  const [imagesList, setImagesList] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ percent: Math.round(progressRange * 100) });
  }, [progressRange]);

  const onSubmit = async (event) => {
    setLoad(true);
    if (event.percent < activity.completed * 100) {
      messageApi.error(
        "El porcentaje de avance no puede ser menor al avance actual"
      );
      setLoad(false);
      return;
    }

    if (imagesList.length < 1) {
      messageApi.error("Por favor sube al menos una imagen");
      return;
    }

    const data = {
      ...event,
      serviceId: id,
      activityId: key,
      percent: event.percent / 100,
      images: imagesList,
      emisorId: user.id,
      emisor: {
        name: user.register_data.name,
        email: user.register_data.contact.email_associated,
        user_type: user.user_type,
        thumbnail: user.register_data.thumbnail,
      },
    };

    const uploadImages = async () => {
      const response = await uploadMultipleEvidenceResources(data.images);
      const images = response.map((item) => item.url);
      return images;
    };

    try {
      const imageUrls = await uploadImages();
      const updatedImagesList = imagesList.map((image, index) => ({
        ...image,
        url: imageUrls[index],
      }));

      const updatedData = {
        ...data,
        images: updatedImagesList,
      };

      setImagesList([]);

      //send advance
      await createAdvance(updatedData);
      //go back screen
      history.goBack();
      setLoad(false);
    } catch (error) {
      messageApi.error("Error al subir las imágenes");
      console.error(error);
    }
  };

  const onDeleteImage = (index) => {
    const newImagesList = imagesList.filter((_, i) => i !== index);
    setImagesList(newImagesList);
  };

  return (
    <main className="new-advance-form">
      <GoBackBtn />

      <header style={{ color: "#652d52" }}>
        <h1>{activity.name}</h1>
        <Icon />
      </header>

      <Form form={form} className="update-form" onFinish={onSubmit}>
        <Form.Item
          label="Porcentaje de avance"
          name="percent"
          rules={[
            { required: true, message: "Por favor modifique este valor" },
          ]}
          className="percentage-update"
        >
          <div className="percentage-container">
            <Slider
              min={1}
              max={100}
              trackStyle={
                activity.completed < 0.2
                  ? { backgroundColor: "grey" }
                  : activity.completed < 1
                  ? { backgroundColor: "#1677ff" }
                  : { backgroundColor: "#52c41a" }
              }
              value={
                progressRange < activity.completed
                  ? activity.completed * 100
                  : progressRange * 100
              }
              onChange={(newValue) => {
                setProgressRange(newValue / 100);
              }}
            />

            <InputNumber
              min={activity.completed * 100}
              max={100}
              maxLength={3}
              value={Math.round(progressRange * 100)}
              addonAfter="%"
              onChange={(value) => {
                setProgressRange(value / 100);
              }}
              size="large"
            />
          </div>

          {progressRange * 100 >= 80 && (
            <Button
              size="large"
              className="complete-all-btn"
              onClick={() => {
                setProgressRange(1);
              }}
            >
              Completar toda la actividad
            </Button>
          )}
        </Form.Item>

        <section className="optional-advance">
          <h1 style={{ color: "#652d52" }}>Opcional</h1>

          <Form.Item name="comment" label="Comentarios">
            <Input.TextArea
              className="optional-comment"
              placeholder="Describe a detalle el avance que has realizado en la obra, explicate lo mejor posible ..."
            />
          </Form.Item>
          <InputFile
            images={imagesList}
            onAddFiles={setImagesList}
            filesAccept={[".png", ".jpg", ".heic", ".jpeg"]}
            deleteMediaSource={onDeleteImage}
          />
        </section>

        <Button loading={load} htmlType="submit" size="large">
          Guardar avance
        </Button>
        {contextHolder}
      </Form>
    </main>
  );
}
