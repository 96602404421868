import React from "react";
import Message from "./Message";
import { useSelector } from "react-redux";

const MessageController = ({ message, key }) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const user = useSelector((state) => state.auth.user);
  const userId = user?.id;
  const emisorId = message?.emisor?.id;
  const isMine = emisorId === userId ? "-mine" : "";

  return (
    <Message
      key={key}
      isMine={isMine}
      message={message}
      visible={!message.is_visible}
      showDetail={showDetail}
      setShowDetail={setShowDetail}
    />
  );
};

export default MessageController;
