import NavBar from 'components/Navbar/NavBar'
import { withRouter } from 'react-router-dom'
import ServiceProviderMenuDesktop from '../../../components/molecules/menu/ServiceProviderMenu'
import ProfileTemplate from '../../../components/templates/profileTemplate/ProfileTemplate'
import { camelCase } from '../../../res/utils'
import { PS_PROFILE } from '../../../router/routes'

const ServiceProviderProfile = ({
    thumbnail,
    name,
    userType,
    callbackSelectImage,
    callbackSelectImageError,
    appbarButtonsContent,
    menuButtonsContent,
    score
}) => {

    return (
        <div className='sp-profile-container service-provider '>
            <ServiceProviderMenuDesktop page={PS_PROFILE} />

            <ProfileTemplate
                appbarButtonsContent={appbarButtonsContent}
                menuButtonsContent={menuButtonsContent}
                thumbnail={thumbnail}
                name={camelCase(name)}
                score={score}
                userType={userType}
                callbackSelectImage={callbackSelectImage}
                callbackSelectImageError={callbackSelectImageError}
                navbar={
                    <NavBar />
                }

            />

        </div>
    )
}


export default withRouter(ServiceProviderProfile)
