import { Avatar, Empty, Input, Progress, Skeleton } from "antd";
import jpSubPicture from "assets/icons/png/jp-sub-picture.png";
import GoBackBtn from "components/GoBackBtn/GoBackBtn";
import NavBar from "components/Navbar/NavBar";
import {
  configClass,
  configTitleIcon,
} from "components/molecules/container/Container";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NEW_ADVANCE_DETAIL } from "router/routes";
import { allServicesAcceptByServiceProvider } from "services-controller/http.cleinte.controller";
import "./AllServicesAdded.css";

export default function AllServicesAdded() {
  const history = useHistory();
  const [servicesState, setServicesState] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    list: [],
  });
  const [name, setName] = useState("");

  const getServices = async () => {
    setServicesState((state) => ({ ...state, isloading: true }));
    const services = await allServicesAcceptByServiceProvider();
    setServicesState((state) => ({
      ...state,
      isloading: false,
      isSuccess: true,
      list: services,
    }));
  };

  const onSearch = (textValue = "") => {
    setTimeout(() => {
      const text = textValue.trim();

      setName(text);
    }, 1500);
  };

  const filteredServices = servicesState.list.filter((item) => {
    const nameUser = item.metadata?.user?.register_data?.name;

    if (nameUser?.toLowerCase()?.includes(name.toLowerCase())) {
      return item;
    }

    return null;
  });

  useEffect(() => {
    getServices();
  }, []);

  return (
    <main className="new-advance">
      <GoBackBtn />

      <section className="new-advance-body">
        <p>Selecciona el servicio al que quieres agregar un nuevo avance</p>

        <Input
          placeholder="Buscar"
          onChange={(event) => {
            onSearch(event.target.value);
          }}
          className="new-advance-filter"
          size="large"
          type="search"
        />

        <section className="new-advance-list">
          {servicesState.isLoading && (
            <Skeleton
              style={{ width: "100%" }}
              paragraph={{ rows: 20 }}
              active
            />
          )}

          {!servicesState.list.length && servicesState.isSuccess && (
            <Empty
              description="No hay servicios para mostrar"
              style={{ marginBlock: "auto", width: "100%" }}
            />
          )}

          {Boolean(servicesState.list.length) &&
            servicesState.isSuccess &&
            filteredServices
              .filter((item) => item.version === 2)
              .map((item) => {
                const { metadata, unique_key, state } = item;

                return (
                  <article
                    key={unique_key}
                    className="list-item"
                    onClick={() => {
                      history.push(`/new-advance-detail/${unique_key}`);
                    }}
                  >
                    <Avatar
                      src={
                        metadata?.user?.register_data?.thumbnail || jpSubPicture
                      }
                      size={42}
                    />

                    <span className="item-title">
                      {metadata?.user?.register_data?.name}
                      {` - ${unique_key}`}
                    </span>

                    <span
                      className={`${configClass(state?.stage)} item-status`}
                    >
                      {configTitleIcon(state?.stage)}
                    </span>

                    <Progress
                      percent={Math.round(
                        state?.globalState?.progress?.progress * 100
                      )}
                      size="small"
                      className="item-progress-bar"
                    />
                  </article>
                );
              })}
        </section>
      </section>

      <NavBar />
    </main>
  );
}
